





























































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { get as _get } from 'lodash';
import {
  Application,
  Product,
  ProductFormValues
} from '@/store/modules/application/types/application.types';
import { ApplicationCategory } from '../../../jbi-shared/types/jaas-app.types';
import { SubscriptionDurationType } from '@/store/modules/subscription/types/subscription.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

const initialFormValue: ProductFormValues = {
  id: undefined,
  appId: 'sumari',
  category: ApplicationCategory.Software,
  name: '',
  description: '',
  duration: 0,
  durationType: 'MONTHS',
  licenseModelIds: [],
  currency: 'AUD',
  price: 0,
  adminOnly: true,
  gstApplicable: true,
  isDeleted: false,
  exclusionDuration: 90,
  exclusionDurationType: SubscriptionDurationType.DAYS,
  chargeCode: ''
};

@Component({
  components: {
    ValidationProvider
  }
})
export default class ProductForm extends Vue {
  public form: ProductFormValues = {
    ...initialFormValue
  };

  @Prop() public product!: Product;

  @State(({ application }: RootState) => application.licenseModels)
  public licenseModels!: any;

  @Action('application/getApplications')
  public getApplications!: (category?: string) => Promise<void>;

  @State(
    (state: RootState) => state.application.productApplications?.applications
  )
  public applications!: Application[];

  get currencies() {
    return ['AUD', 'USD'];
  }

  get isCreating() {
    return !this.product;
  }
  get isEditing() {
    return !!this.product;
  }

  get freeProductError() {
    if (this.totalAmount === 0 && !this.form.adminOnly) {
      return 'By selecting general, any user can access this product for free';
    }
  }

  @Watch('applications')
  @isTruthy
  public watchApplications(newValue: Application[]) {
    const appIdArray = newValue.map((val) => val.id);
    if (!appIdArray.includes(this.form.appId) && appIdArray.length > 0) {
      this.form.appId = appIdArray[0];
    }
  }

  public mounted() {
    if (this.isEditing) {
      this.form = {
        id: this.product.id,
        name: this.product.name,
        description: this.product.description,
        duration: this.product.duration,
        durationType: this.product.durationType,
        licenseModelIds: this.product.licenseModels.map(
          (licenseModel: any) => licenseModel.id
        ),
        currency: this.product.currentPrice.currency,
        price: parseFloat(
          (this.product.chargeDetail.netPrice / 100).toFixed(2)
        ),
        adminOnly: this.product.adminOnly,
        gstApplicable: this.product.currentPrice.gstApplicable,
        appId: this.product.applicationId,
        category: this.product.application.category
          ? this.product.application.category
          : ApplicationCategory.Software,
        isDeleted: this.product.isDeleted,
        exclusionDuration: this.product.exclusionDuration,
        exclusionDurationType: this.product.exclusionDurationType,
        chargeCode: this.product.chargeCode
      };
    } else if (this.isCreating) {
      const defaultLicenseModelId = _get(this.licenseModels, '[0].id');
      this.form = {
        ...this.form,
        licenseModelIds: defaultLicenseModelId ? [defaultLicenseModelId] : []
      };
    }

    this.getApplications(this.form.category);
  }

  get durationTypes() {
    return ['MONTHS', 'YEARS', 'DAYS'];
  }

  public closeModal() {
    this.$emit('closeModal');
  }

  public handleSubmit() {
    // we do not want to save category, we can manage through application ID
    // we do not want to change product table schema
    delete this.form.category;
    return this.$emit('submit', this.form);
  }

  // TODO: Once GST is completly implemented fetch the GST value from backend
  get totalAmount() {
    const price = this.form.price * 100;

    if (this.form.gstApplicable) {
      const gst = price * 0.1;
      return price + gst;
    }

    return price;
  }

  get isTotalInDecimal() {
    return (this.totalAmount / 100) % 1 !== 0;
  }

  get freeProductNotification() {
    return this.totalAmount === 0 && !this.form.adminOnly;
  }

  public changeApplication() {
    this.getApplications(this.form.category);
  }

  // Watch form field value change and enable or disable submit button
  @Watch('form.name')
  @Watch('form.description')
  @Watch('form.duration')
  @Watch('form.exclusionDuration')
  @Watch('form.price')
  public watchFormValues() {
    if (
      this.form.name &&
      this.form.description &&
      this.form.duration &&
      this.form.duration > 0 &&
      this.form.exclusionDuration &&
      this.form.price.toString() !== ''
    ) {
      this.$emit('changeDisabledButton', false);
    } else {
      this.$emit('changeDisabledButton', true);
    }
  }
}
