var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.$refs.ProductForm.handleSubmit();
          _vm.$refs.ProductForm.closeModal();
        });
      })($event)}}},[_c('BaseModal',{attrs:{"title":"Create Product","size":"medium"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_c('span',{class:['button', 'is-primary'],attrs:{"data-test":"button-create-product-popup"},on:{"click":openModal}},[_vm._v(" Create Product ")])]}},{key:"modalBody",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('ProductForm',{ref:"ProductForm",on:{"submit":_vm.handleSubmit,"closeModal":closeModal,"changeDisabledButton":_vm.handleSubmitButtonDisabledChange}})]}},{key:"modalFoot",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","data-test":"button-create-product","disabled":_vm.isSubmitButtonDisabled},on:{"closeModal":closeModal}},[_vm._v(" Create Product ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }