

















































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import ProductForm from '@/views/ProductManagement/components/ProductForm.vue';
import { ProductFormValues } from '../../../store/modules/application/types/application.types';

@Component({
  components: {
    BaseModal,
    ProductForm,
    ValidationObserver
  }
})
export default class CreateProductButton extends Vue {
  @Action('application/createProduct')
  public createProduct!: (values: ProductFormValues) => Promise<void>;

  public isSubmitButtonDisabled: boolean = true;

  public async handleSubmit(values: ProductFormValues) {
    await this.createProduct(values);
  }

  public handleSubmitButtonDisabledChange(newValue: boolean) {
    this.isSubmitButtonDisabled = newValue;
  }
}
