












































import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilteredProductPayload } from '../../../store/modules/application/types/application.types';

@Component({
  components: {}
})
export default class SearchBox extends Vue {
  @Prop(Object) public filterParams!: FilteredProductPayload;

  @Watch('filterParams', { deep: true })
  @Debounce(500)
  public onChange() {
    this.$emit('input', this.filterParams);
  }
  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
