















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import ProductForm from '@/views/ProductManagement/components/ProductForm.vue';
import {
  DeleteProductValues,
  Product,
  ProductFormValues
} from '../../../store/modules/application/types/application.types';
import { DialogProgrammatic as Dialog } from 'buefy';
import delay from 'delay';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseModal,
    ProductForm,
    ValidationObserver
  }
})
export default class EditProductButton extends Vue {
  @Prop() public product!: Product;

  @Prop(String) public currentProduct!: string;

  @Action('application/updateProduct')
  public updateProduct!: (values: ProductFormValues) => Promise<void>;

  @Action('application/deleteProduct')
  public deleteProduct!: (values: DeleteProductValues) => Promise<void>;

  @Action('application/getApplications')
  public getApplications!: () => Promise<void>;

  @Action('application/setCurrentProduct')
  public setCurrentProduct!: (productName: string) => void;

  public async handleSubmit(values: ProductFormValues) {
    await this.updateProduct(values);
  }
  public async handleDeleteProduct(id: number) {
    this.setCurrentProduct(this.product.name);
    const dialogElem: Vue = Dialog.confirm({
      message: `<p class="buefy-dialog-title">Delete ${this.product.name}?</p><p class="buefy-dialog-content">${this.product.name} will be rendered inactive and the application tied to this product will no longer accessible</p>`,
      confirmText: 'Cancel',
      onConfirm: () => undefined,
      canCancel: ['button'],
      cancelText: 'Delete',
      onCancel: async () => {
        await this.deleteProduct({
          id,
          isDeleted: true
        });
      }
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }
}
