



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import PaginatedProductList from '@/views/ProductManagement/components/PaginatedProductList.vue';
import Container from '@/components/Container.vue';
import CreateProductButton from '@/views/ProductManagement/components/CreateProductButton.vue';
import { ApiState, PaginatedResponse } from '../../store/types/general.types';
import {
  FilteredProductPayload,
  Product
} from '../../store/modules/application/types/application.types';
import { get as _get } from 'lodash';
import SearchBox from '@/views/ProductManagement/components/SearchBox.vue';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

const initialState = {
  productName: '',
  status: 'active'
};

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    Container,
    PaginatedProductList,
    CreateProductButton,
    SearchBox
  }
})
export default class ProductManagementPage extends Vue {
  public userCheckBoxGroup: string[] = [];
  public limit: number = 50;
  public page: number = 1;
  public perPage = 50;
  public sortColumn: string = 'productId';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredProductsParams: FilteredProductPayload = { ...initialState };
  public onLoad = false;

  @Action('application/getLicenseModels')
  public getLicenseModels!: () => Promise<void>;

  @Action('application/getAdministrationProducts')
  public getAdministrationProducts!: ({
    limit,
    page,
    sortColumn,
    sortOrder,
    productName,
    status
  }: {
    limit: number;
    page: number;
    sortColumn: string;
    sortOrder: 'ASC' | 'DESC';
    productName?: string;
    status?: string;
  }) => Promise<void>;

  @State(({ application }: RootState) => application.administrationProducts)
  public administrationProducts!: PaginatedResponse<Product>;

  @State((state: RootState) => state.application.apiState.createProduct)
  public createProductState!: ApiState;

  @State((state: RootState) => state.application.apiState.updateProduct)
  public updateProductState!: ApiState;

  @State((state: RootState) => state.application.apiState.disableProduct)
  public disableProductState!: ApiState;

  @State((state: RootState) => state.application.apiState.deletedProduct)
  public deleteProductState!: ApiState;

  @State((state: RootState) => state.application.currentProduct)
  public currentProduct!: string;

  @State((state: RootState) => state.application.currentProductStatus)
  public currentProductStatus!: string;

  public async mounted() {
    this.getLicenseModels();
    this.filteredProductsParams = {
      productName: (this.$route.query.productName as string) || '',
      status: (this.$route.query.status as string) || 'active'
    };

    this.$router.push({
      path: this.$route.path,
      query: {
        productName: this.$route.query.productName as string,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
        status:
          this.$route.query.status ||
          (this.filteredProductsParams.status as string)
      }
    });
    this.perPage = +this.$route.query.limit || this.limit;
    this.page = +this.$route.query.page || this.page;

    this.getAdministrationProducts({
      productName:
        (this.$route.query.productName as string) ||
        this.filteredProductsParams.productName,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  get product() {
    return {
      items: _get(this.administrationProducts, 'items', []).filter(
        (res) => !res.isDeleted
      ),
      totalItems: _get(this.administrationProducts, 'totalItems', 0)
    };
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    await this.getAdministrationProducts({
      productName:
        (this.$route.query.productName as string) ||
        this.filteredProductsParams.productName,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });

    this.$router.push({
      path: this.$route.path,
      query: {
        productName:
          this.$route.query.productName ||
          (this.filteredProductsParams.productName as string),
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
        status:
          (this.$route.query.status as string) ||
          (this.filteredProductsParams.status as string)
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: FilteredProductPayload = {
      productName: (this.$route.query.productName as string) || '',
      status: this.$route.query.status as string
    };

    if (
      JSON.stringify(queryFilter) !==
      JSON.stringify(this.filteredProductsParams)
    ) {
      this.page = 1;
      await this.getAdministrationProducts({
        productName: this.filteredProductsParams.productName || undefined,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
        status: this.filteredProductsParams.status
      });

      this.$router.push({
        path: this.$route.path,
        query: {
          productName: this.filteredProductsParams.productName as string,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
          status: this.filteredProductsParams.status as string
        }
      });
    }
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    await this.getAdministrationProducts({
      productName:
        (this.$route.query.productName as string) ||
        this.filteredProductsParams.productName,
      limit: perPage,
      page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
    this.$router.push({
      path: this.$route.path,
      query: {
        productName:
          this.$route.query.productName ||
          (this.filteredProductsParams.productName as string),
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
        status:
          (this.$route.query.status as string) ||
          (this.filteredProductsParams.status as string)
      }
    });
  }

  @isDifferent
  @isTruthy
  @Watch('updateProductState.success')
  public onUpdateProductSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Product Updated Successfully`
    });

    this.getAdministrationProducts({
      productName: this.$route.query.productName as string,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  @isDifferent
  @isTruthy
  @Watch('updateProductState.error')
  public onUpdateProductError(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Failed to edit product. Please try again later.`
    });
  }

  @isDifferent
  @isTruthy
  @Watch('createProductState.success')
  public onCreateProductSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Product Created Successfully`
    });

    this.getAdministrationProducts({
      productName: this.$route.query.productName as string,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  @isDifferent
  @isTruthy
  @Watch('createProductState.error')
  public onCreateProductError(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Failed to create product. Please try again later.`
    });
  }

  @isDifferent
  @isTruthy
  @Watch('disableProductState.success')
  public onDisableProductSuccess(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.currentProduct} has been ${this.currentProductStatus}.`
    });

    this.getAdministrationProducts({
      productName: this.$route.query.productName as string,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  @isDifferent
  @isTruthy
  @Watch('disableProductState.error')
  public onDisableProductError(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Failed to disable ${this.currentProduct}. Please try again later.`
    });
  }

  @isDifferent
  @isTruthy
  @Watch('deleteProductState.success')
  public onDeleteProductSuccess(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.currentProduct} has been deleted.`
    });

    // Navigate to previous page if the last item of the current page is deleted.
    if (this.product.items.length === 1 && this.page !== 1) {
      this.page -= 1;
      this.handlePaginator({ perPage: this.perPage, page: this.page });
    }

    this.getAdministrationProducts({
      productName: this.$route.query.productName as string,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  @isDifferent
  @isTruthy
  @Watch('deleteProductState.error')
  public onDeleteProductError(e: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Failed to delete ${this.currentProduct}. Please try again later.`
    });
    this.getAdministrationProducts({
      productName: this.$route.query.productName as string,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      status:
        (this.$route.query.status as string) ||
        this.filteredProductsParams.status
    });
  }

  public handleResetFilter() {
    this.filteredProductsParams = { ...initialState };
  }
}
