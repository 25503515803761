





















































































































import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  DisableProductValues,
  Product
} from '@/store/modules/application/types/application.types';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import EditProductButton from '@/views/ProductManagement/components/EditProductButton.vue';
import { DialogProgrammatic as Dialog } from 'buefy';
import { mixins } from 'vue-class-component';
import { RootState } from '@/store/store';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import BaseTable from '@/components/BaseTable.vue';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    BaseTable,
    SortableTableHeader,
    BasePagination,
    BaseLoading,
    EditProductButton
  }
})
export default class ProductList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Products', sortColumn: 'productName', sort: true },
    {
      placeholder: 'Price',
      sortColumn: 'priceCurrency, priceAmount',
      sort: true
    },
    { placeholder: 'GST Amount', sortColumn: 'gst', sort: false },
    { placeholder: 'Total Price', sortColumn: 'currency, amount', sort: true },
    { placeholder: 'License', sortColumn: 'license', sort: false },
    { placeholder: 'Status', sortColumn: 'productStatus', sort: false }
  ];

  @Prop() public items!: Product;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @Action('application/disableProduct')
  public disableProduct!: (values: DisableProductValues) => Promise<void>;

  @Action('application/setCurrentProduct')
  public setCurrentProduct!: (productName: string) => void;

  @Action('application/setCurrentProductStatus')
  public setCurrentProductStatus!: (productState: string) => void;

  @State(
    ({ application }: RootState) =>
      application.apiState.getAdministrationProduct.success
  )
  public getAdministrationProductSuccess!: boolean;

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public licenseModelsFormater(licenseModels: any[]) {
    return licenseModels
      .map((licenseModel) => {
        return licenseModel.applicationResource.entityName;
      })
      .join(', ')
      .replace('_', ' ');
  }

  public async toggleStatus(product: any) {
    const action = product.isActive ? 'Deactivate' : 'Activate';
    this.setCurrentProduct(product.name);

    if (!product.isActive) {
      this.setCurrentProductStatus('activated');
      return await this.disableProduct({
        id: product.id,
        isActive: !product.isActive
      });
    }
    this.setCurrentProductStatus('deactivated');

    return Dialog.confirm({
      message: `<p class="buefy-dialog-title">${action} product ${product.name}?</p><p class="buefy-dialog-content">${product.name} and the application tied to this product will no longer be accessible to users.</p>`,
      confirmText: product.isActive ? 'Deactivate' : 'Activate',
      type: 'is-primary',
      onConfirm: async () => {
        await this.disableProduct({
          id: product.id,
          isActive: !product.isActive
        });
      }
    });
  }
}
