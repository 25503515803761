var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        if (!invalid) {
          _vm.$refs.ProductForm.handleSubmit();
          _vm.$refs.ProductForm.closeModal();
        }
      })($event)}}},[_c('BaseModal',{attrs:{"title":"Edit Product","size":"medium"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_c('b-dropdown',{class:[_vm.$style.dropdownContainer],attrs:{"position":"is-bottom-right","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"icon fa fa-ellipsis-h",class:_vm.$style.ellipsisColor,attrs:{"role":"button"}})]},proxy:true}],null,true)},[(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'product_administration-update_products'
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":openModal}},[_vm._v(" Edit")]):_vm._e(),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.DELETE,
                'product_administration-delete_products'
              )
            )?_c('b-dropdown-item',{class:[_vm.$style.deleteButtonContainer],attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.handleDeleteProduct(_vm.product.id)}}},[_vm._v("Delete")]):_vm._e()],1)]}},{key:"modalBody",fn:function(ref){
            var closeModal = ref.closeModal;
return [_c('ProductForm',{ref:"ProductForm",attrs:{"product":_vm.product},on:{"submit":_vm.handleSubmit,"closeModal":closeModal}})]}},{key:"modalFoot",fn:function(ref){
            var closeModal = ref.closeModal;
return [_c('button',{staticClass:"button is-primary",attrs:{"data-test":"edit-product-button","type":"submit","disabled":invalid},on:{"closeModal":closeModal}},[_vm._v(" Save ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }