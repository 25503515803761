var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.labelWrapper},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 200 },"name":"Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",attrs:{"data-test":"create-product-name","maxlength":"100"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])]}}])}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Category","type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0] || ''}},[_c('b-select',{attrs:{"value":"software","expanded":""},on:{"input":_vm.changeApplication},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},[_c('option',{attrs:{"value":"Software"}},[_vm._v("Software")]),_c('option',{attrs:{"value":"Training"}},[_vm._v("Training")])])],1)],1),_c('div',{staticClass:"column"},[(_vm.form.category === 'Software')?_c('b-field',{attrs:{"label":"Application","type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0] || ''}},[_c('b-select',{attrs:{"data-test":"product-application","expanded":""},model:{value:(_vm.form.appId),callback:function ($$v) {_vm.$set(_vm.form, "appId", $$v)},expression:"form.appId"}},_vm._l((_vm.applications),function(application,index){return _c('option',{key:("product_" + index),domProps:{"value":application.id}},[_vm._v(" "+_vm._s(application.name)+" ")])}),0)],1):_vm._e()],1)]),_c('ValidationProvider',{attrs:{"rules":{ required: true, min: 0 },"name":"License"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"License","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},_vm._l((_vm.licenseModels),function(licenseModel){return _c('b-checkbox',{key:licenseModel.id,attrs:{"native-value":licenseModel.id},model:{value:(_vm.form.licenseModelIds),callback:function ($$v) {_vm.$set(_vm.form, "licenseModelIds", $$v)},expression:"form.licenseModelIds"}},[_c('span',{class:_vm.$style.checkBoxValue},[_vm._v(_vm._s(licenseModel.applicationResource.entityName.replace('_', ' '))+" ")])])}),1)]}}])}),_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 500 },"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Description","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"}],staticClass:"textarea",attrs:{"data-test":"product-application-description","maxlength":"500"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}})])]}}])}),_c('div',{staticClass:"columns"},[_c('div',{class:['column', 'is-1', _vm.$style.durationValue]},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 1 },"name":"Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Duration","expanded":"","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.duration),expression:"form.duration"}],class:['input', _vm.$style.durationInput, 'is-fullwidth'],attrs:{"data-test":"product-application-duration","type":"number","min":"1"},domProps:{"value":(_vm.form.duration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "duration", $event.target.value)}}})])]}}])})],1),_c('div',{staticClass:"column"},[_c('b-field',{class:_vm.$style.durationTypeOption,attrs:{"type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0]}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.durationType),callback:function ($$v) {_vm.$set(_vm.form, "durationType", $$v)},expression:"form.durationType"}},_vm._l((_vm.durationTypes),function(durationType,index){return _c('option',{key:("duration_" + index),domProps:{"value":durationType}},[_vm._v(" "+_vm._s(durationType)+" ")])}),0)],1)],1),_c('div',{class:['column', 'is-1', _vm.$style.durationValue]},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 },"name":"ExclusionDuration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Exclusion Period","expanded":"","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.exclusionDuration),expression:"form.exclusionDuration"}],class:['input', _vm.$style.durationInput, 'is-fullwidth'],attrs:{"data-test":"product-application-duration","type":"number","min":"0"},domProps:{"value":(_vm.form.exclusionDuration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "exclusionDuration", $event.target.value)}}})])]}}])})],1),_c('div',{staticClass:"column"},[_c('b-field',{class:_vm.$style.durationTypeOption,attrs:{"type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0]}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.exclusionDurationType),callback:function ($$v) {_vm.$set(_vm.form, "exclusionDurationType", $$v)},expression:"form.exclusionDurationType"}},_vm._l((_vm.durationTypes),function(durationType,index){return _c('option',{key:("duration_" + index),domProps:{"value":durationType}},[_vm._v(" "+_vm._s(durationType)+" ")])}),0)],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Currency","type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0]}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}},_vm._l((_vm.currencies),function(currency,index){return _c('option',{key:("currency_" + index),domProps:{"value":currency}},[_vm._v(" "+_vm._s(currency)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required|decimal:2|min:0","name":"Product Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product Price (excluding GST)","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.price),expression:"form.price"}],staticClass:"input",attrs:{"data-test":"product-application-price","type":"number","min":"0","step":"any","max":"2500"},domProps:{"value":(_vm.form.price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "price", $event.target.value)}}})])]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":"Charge Code (Optional)"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.chargeCode),expression:"form.chargeCode"}],staticClass:"input",attrs:{"maxlength":"20"},domProps:{"value":(_vm.form.chargeCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "chargeCode", $event.target.value)}}})])],1)]),_c('ValidationProvider',{attrs:{"name":"GST Applicable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('span',{attrs:{"title":"If enabled, during purchase the user will be evaluated for GST application. If this is disabled, GST will not be applied during the purchase of this product"}},[_c('b-checkbox',{model:{value:(_vm.form.gstApplicable),callback:function ($$v) {_vm.$set(_vm.form, "gstApplicable", $$v)},expression:"form.gstApplicable"}},[_c('span',{class:_vm.$style.checkBoxValue},[_vm._v("GST Applicable (If enabled, the user may be charged with GST) ")])])],1)])]}}])}),_c('b-field',{attrs:{"label":"Total Amount after GST"}},[_c('span',{class:_vm.$style.totalAmount},[_vm._v(" "+_vm._s(_vm.moneyParser({ currency: _vm.form.currency, value: _vm.totalAmount }))+" "),(_vm.isTotalInDecimal)?_c('span',{staticClass:"m-l-1 has-text-danger"},[_vm._v(" This won't work in Staging/Test Environment ")]):_vm._e()])]),_c('b-field',{attrs:{"label":"Product Access","type":_vm.freeProductNotification ? 'is-danger' : undefined,"message":_vm.freeProductError}},[_c('div',{class:_vm.$style.userTypeRadio},[_c('b-radio',{attrs:{"native-value":true},model:{value:(_vm.form.adminOnly),callback:function ($$v) {_vm.$set(_vm.form, "adminOnly", $$v)},expression:"form.adminOnly"}},[_c('span',{class:_vm.$style.checkBoxValue,attrs:{"data-test":"admin-user-product"}},[_vm._v("ADMIN ONLY")])]),_vm._v(" "),_c('b-radio',{attrs:{"native-value":false},model:{value:(_vm.form.adminOnly),callback:function ($$v) {_vm.$set(_vm.form, "adminOnly", $$v)},expression:"form.adminOnly"}},[_c('span',{class:_vm.$style.checkBoxValue,attrs:{"data-test":"general-user-product"}},[_vm._v("GENERAL USER")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }