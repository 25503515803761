














import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import ProductList from './ProductList.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { Product } from '@/store/modules/application/types/application.types';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedProductList extends mixins(PaginationMixin) {
  @Prop() public products!: Product;
  @Prop() public totalCount!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  get ProductList() {
    return ProductList;
  }
}
